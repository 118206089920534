.rich-text-form {
  border: solid 1px grey;
  padding: 2px;
}

.menu-bar-container {
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.menu-bar-rows {
  display: flex;
  flex-direction: row;
  div {
    margin-right: 5px;
    padding: 1px;
  }
  div:hover {
    color: grey;
    cursor: pointer;
  }
}

.is-active {
  color: green;
}

.custom_bold {
  font-weight: bold;
}

.custom_italic {
  font-style: italic;
}

.custom_strike {
  text-decoration: line-through;
}

.custom_underline {
  text-decoration: underline;
}

.rich-text-editor {
  border: solid 1px grey;
}

.ProseMirror {
  border: solid 1px grey;
  min-height: 4em;
}
