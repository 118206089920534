.skills-container {
  overflow: auto;
  height: 57vh;
}

.skill-container {
  width: 50vw;
  margin: auto;
}

.skill-link {
  text-decoration: none;
  color: black;
}

.skill-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 3em;
  background-color: #D8D8D8;
  border: 1px solid #D8D8D8;
  border-radius: 9px;
  margin: 2px;
  text-align: center;
}

.skill-list-item:hover {
  background-color: #F0F0F0;
  cursor: pointer;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  padding-bottom: 2em;
  margin: auto;
  text-align: center;
}

.pagination-arrow {
  margin-top: 2px;
}

.button-6 {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-6:hover {
  transform: translateY(-1px);
}

.skill-buttons {
  width: 80vw;
  margin: auto;
}

.skill-body-container {
  margin: auto;
  width: 80vw;
}

.skill-comment-container {
  margin: 2em auto;
  width: 80vw;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.skill-comment {
  padding: 2px;
  width: 70%;

}

.skill-comment-form {
  padding: 2px;
  width: 30%;
}

.skill-comment-comment {
  padding: 2px;
  margin: 2px;
  background-color: #f2f2f2;
}

// skill form
.add-skill-container {
  width: 80vw;
  margin: auto;
}

.update-skill-form {
  width: 80vw;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .skill-container {
    width: 90vw;
    margin: auto;
  }

  .pagination-container {
    width: 90vw;
  }
}


@media only screen and (max-width: 800px) {
  .skill-container {
    width: 70vw;
    margin: auto;
  }

  .pagination-container {
    width: 70vw;
  }
}