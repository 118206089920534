.nav-container {
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
}

.nav-title {
  width: 60%;
  height: 6em;
}

.nav-bar {
  height: 6em;

  a {
    text-decoration: none;
    color: black;
  }

  a:hover {
    color: grey
  }
}

@media only screen and (max-width: 600px) {
  .nav-container {
    flex-direction: column;
  }
  .nav-title {
    width: 90%;
    height: 6em;
  }
}